import type { AppProps } from "next/app";
import { wrapper } from "@/store/store";
import React from "react";
import { appDispatch, appSelector } from "@/store/hooks";
import { getSession } from "@/features/admin/login";
import { Provider, useStore } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Script from "next/script";

// add bootstrap css
// import "bootstrap/dist/css/bootstrap.css";
import "react-slideshow-image/dist/styles.css";
import "@/public/static/css/stylesheet.css";
import "@/public/static/css/font.css";

//slide
import "../public/static/css/embla.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function MyApp({ Component, pageProps }: AppProps) {
  const store: any = useStore();
  const dispatch = appDispatch();
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `5px solid green`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: `5px dotted red`,
              },
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <>
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-JTEBGZFVXH"
          ></Script>

          <Script id="google-analytics" strategy="afterInteractive">
            {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-JTEBGZFVXH');
      `}
          </Script>
        </>
        <PersistGate persistor={store.__persistor} loading={null}>
          <Component {...pageProps} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default wrapper.withRedux(MyApp);
